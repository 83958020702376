<template>
  <m-form :formItems="formItem" @handleGet="handleGet(0)" />
  <m-table
    :buttons="buttons"
    :loading="loading"
    :columns="columns"
    :data="tableList"
    :totals="total"
    :currentPage="req.pageNum"
    @pageChange="pageChange($event, 0)"
    @modifyStatus="modifyStatus($event)"
    @tdClick="tdClick"
    :scrollY="470"
  />
  <a-modal
    wrapClassName="myModal"
    :maskClosable="false"
    width="90%"
    height="95%"
    @cancel="show = false"
    :visible="show"
    :title="`${shopName}-${orderInfoType == 'sellAmount' ? '订单详情列表' : `${reqOrderInfo.type?'退':'进'}货详情`}`"
  >
    <m-form
      :formItems="formItemOrderInfo"
      @handleGet="handleGet(1)"
      @exportTable="exportShopOrderInfo"
    />
    <m-table
      :scrollY="400"
      :buttons="buttons"
      :loading="loading1"
      :columns="orderInfoType == 'sellAmount' ? orderInfoColumns1: orderInfoColumns2"
      :data="orderInfoList"
      :totals="orderInfoTotal"
      :currentPage="reqOrderInfo.pageNum"
      @pageChange="pageChange($event, 1)"
      @modifyStatus="modifyStatus($event)"
      @tdClick="tdClick"
    />
  </a-modal>
</template>

<script>
import { message } from "ant-design-vue";
import {
  shopPurchaseSellStockPage,
  shopOrderInfoPageApi,
  shopPurchaseReturnInfoApi,
  exportShopOrderInfoApi
} from "@/utils/api";
import { reactive, toRefs, watch } from "vue";
import { formatTime, downloadBobl, confirmDialog } from "@/utils/util";

export default {
  setup() {
    const state = reactive({
      loading: true,
      tableList: [],
      total: "",
      req: {
        startTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10
      },
      formItem: [
        { type: "timeRange", label: "查询时间", value: [] },
        { type: "button", label: "查询", eventName: "handleGet" }
      ],
      buttons: [
        { text: "详情", eventName: "detail" },
        { text: "修改", eventName: "modify" }
      ],
      columns: [
        { title: "店铺ID", dataIndex: "shopId", key: "shopId" },
        { title: "店铺名称", dataIndex: "shopName", key: "shopName" },
        {
          title: "进票-金额",
          dataIndex: "purchaseAmount",
          key: "purchaseAmount",
          slots: { customRender: "clickTd" }
        },
        {
          title: "退回-金额",
          dataIndex: "returnStockAmount",
          key: "returnStockAmount",
          slots: { customRender: "clickTd" }
        },
        {
          title: "销售-金额",
          dataIndex: "sellAmount",
          key: "sellAmount",
          slots: { customRender: "clickTd" }
        },
        {
          title: "店铺创建时间",
          dataIndex: "shopCreateTime",
          key: "shopCreateTime"
        }
      ],
      //
      show: false,
      loading1: false,
      orderInfoType: "",
      shopName: "",
      reqOrderInfo: {
        shopId: "",
        startTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10,
        type: ""
      },
      orderInfoList: [],
      orderInfoTotal:0,
      orderInfoColumns1: [
        {
          title: "银联订单号",
          dataIndex: "paymentProviderOrderId",
          key: "paymentProviderOrderId"
        },
        {
          title: "银联成功支付金额",
          dataIndex: "successfulPayActualAmount",
          key: "successfulPayActualAmount"
        },
        {
          title: "钱包成功支付金额",
          dataIndex: "successfulPayWalletAmount",
          key: "successfulPayWalletAmount"
        },
        { title: "状态", dataIndex: "status", key: "status" },
        { title: "创建时间", dataIndex: "createTime", key: "createTime" }
      ],
      orderInfoColumns2: [
        { title: "商品ID", dataIndex: "productInfoId", key: "productInfoId" },
        {
          title: "商品名称",
          dataIndex: "productInfoName",
          key: "productInfoName"
        },
        { title: "票金额", dataIndex: "ticketAmount", key: "ticketAmount" },
        { title: "创建时间", dataIndex: "createTime", key: "createTime" },
      ],
      formItemOrderInfo: [
        { type: "timeRange", label: "查询时间", value: [] },
        { type: "button", label: "查询", eventName: "handleGet" }
      ]
    });

    watch(
      () => state.show,
      val => {
        if (!val) {
          setTimeout(() => {
            state.reqOrderInfo.shopId = "";
            state.reqOrderInfo.pageNum = 1;
            state.reqOrderInfo.startTime = "";
            state.reqOrderInfo.endTime = "";
            state.reqOrderInfo.type = "";
            state.orderInfoList = [];
            if (state.orderInfoType == "sellAmount") {
              state.formItemOrderInfo.splice(2, 1);
            }
          }, 500);
        } else {
          if (state.orderInfoType == "sellAmount") {
            state.formItemOrderInfo.push({
              type: "button",
              label: "导出销量订单",
              eventName: "exportTable"
            });
          }
        }
      }
    );

    async function loadData() {
      state.loading = true;
      const t = state.formItem[0].value;
      state.req.startTime = formatTime(t[0]?._d, "YYYY-MM-DD");
      state.req.endTime = formatTime(t[1]?._d, "YYYY-MM-DD");
      const res = await shopPurchaseSellStockPage(state.req);
      if (res.code == 0) {
        console.log(res.data);
        state.tableList = res.data.records;
        state.total = res.data.total;
        state.loading = false;
      }
    }
    loadData();

    //店铺订单列表-进-退-销
    async function shopOrderInfoPage() {
      state.loading1 = true;
      let api = shopPurchaseReturnInfoApi;
      if (state.orderInfoType == "sellAmount") {
        const t = state.formItemOrderInfo[0].value;
        state.reqOrderInfo.startTime = formatTime(t[0]?._d, "YYYY-MM-DD");
        state.reqOrderInfo.endTime = formatTime(t[1]?._d, "YYYY-MM-DD");
        api = shopOrderInfoPageApi;
      } else if (state.orderInfoType == "purchaseAmount") {
        state.reqOrderInfo.type = 0;
      } else if (state.orderInfoType == "returnStockAmount") {
        state.reqOrderInfo.type = 1;
      }
      const res = await api(state.reqOrderInfo);
      if (res.code == 0) {
        state.orderInfoList = res.data.records;
        state.orderInfoTotal = res.data.total || 0
        state.loading1 = false;
      }
      console.log(res.data);
    }

    function handleGet(val) {
      if (val == 0) {
        state.req.pageNum = 1;
        loadData();
      } else if (val == 1) {
        state.reqOrderInfo.pageNum = 1;
        shopOrderInfoPage();
      }
    }

    function pageChange(e, val) {
      if (val == 0) {
        state.req.pageNum = e;
        loadData();
      } else if (val == 1) {
        state.reqOrderInfo.pageNum = e;
        shopOrderInfoPage();
      }
    }

    function tdClick(e) {
      console.log(e);
      state.show = true;
      state.reqOrderInfo.shopId = e.record.shopId;
      state.shopName = e.record.shopName;
      state.orderInfoType = e.key;
      shopOrderInfoPage();
    }

    async function exportShopOrderInfo() {
      const val = await confirmDialog("提示", "确定导出表格吗？");
      if (!val) return;
      const req = {
        shopId: state.reqOrderInfo.shopId,
        startTime: state.reqOrderInfo.startTime,
        endTime: state.reqOrderInfo.endTime
      };
      const res = await exportShopOrderInfoApi(req);
      console.log(res);
      downloadBobl(res, `${state.shopName}-销量订单`);
    }

    return {
      ...toRefs(state),
      handleGet,
      pageChange,
      tdClick,
      exportShopOrderInfo
    };
  }
};
</script>

<style lang="scss">
.myModal {
  .ant-modal-content {
    height: 100% !important;
  }
  .ant-modal {
    top: 30px !important;
    //   transform: translateY(-50%);
  }
  .ant-modal-body {
    min-height: 85% !important;
    max-height: 85% !important;
    background-color: #fff;
  }
}
</style>